<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="9">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <v-card class="shadow1 rounded white pb-5">
                            <v-toolbar color="white" :elevation="0" style="border-bottom:1px solid #ddd !important;">
                                <v-toolbar-title class="subtitle-2">Supplier Entry</v-toolbar-title>
                            </v-toolbar>

                            <v-card-text class="pt-0 pb-1">
                                <v-form ref="supplierForm" class="custom-form" @submit.prevent="saveSupplier">
                                    <v-row dense>
                                        <v-col cols="6" class="pa-2">
                                            <v-row class="align-center mt-1">
                                                <v-col cols="4" class="py-1">Supplier Type</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :items="['Random Supplier', 'Dealer', 'Impoter']"
                                                        v-model="supplier.type"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row class="align-center mt-1">
                                                <v-col cols="4" class="py-1">Supplier Id</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="supplier.code"
                                                        :rules="[v => !!v]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="align-center mt-1">
                                                <v-col cols="4" class="py-1">Name</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.name"
                                                        :rules="[v => !!v]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <!-- <v-row class="align-center mt-1" v-if="supplier.type == 'Dealer'">
                                                <v-col cols="4" class="py-1">Company Name</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.org_name"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row> -->
                                            <v-row class="align-center mt-1" v-if="supplier.type == 'Random Supplier'">
                                                <v-col cols="4" class="py-1">Father Name</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.father_name"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="align-center mt-1" v-if="supplier.type == 'Random Supplier' || supplier.type == 'Dealer'">
                                                <v-col cols="4" class="py-1">Area</v-col>
                                                <v-col cols="7" class="py-0">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        @focus="$store.dispatch('area/getAreas')"
                                                        :items="$store.getters['area/areas']"
                                                        :loading="$store.getters['area/loadingAreas']"
                                                        item-text="name"
                                                        item-value="id"
                                                        v-model="area"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="1" class="py-0 pa-0"  style="margin-left:-12px">
                                                    <area-dialog :icon="true" ref="areaDialog"></area-dialog>
                                                </v-col>
                                            </v-row>

                                            <v-row class="align-center mt-1" v-if="supplier.type == 'Random Supplier' || supplier.type == 'Dealer'">
                                                <v-col cols="4" class="py-1">Address</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.address"
                                                        :rules="[v => !!v]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-2">
                                                <v-col cols="4" class="py-1">Mobile</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.phone"
                                                        :rules="[v => !!v, () => validateMobile(supplier.phone)]"
                                                        type="number"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1" v-if="supplier.type == 'Random Supplier'">
                                                <v-col cols="4" class="py-1">NID NO.</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.nid"
                                                        type="number"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="6" class="pa-2">

                                            <v-row class="mt-1" v-if="supplier.type == 'Random Supplier' || supplier.type == 'Dealer'">
                                                <v-col cols="4" class="py-1">License NO.</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.license_no"
                                                        type="text"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1" v-if="supplier.type == 'Random Supplier' || supplier.type == 'Dealer'">
                                                <v-col cols="4" class="py-1">Issued By</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.license_issued_by"
                                                        type="text"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1" v-if="supplier.type == 'Random Supplier'">
                                                <v-col cols="4" class="py-1">Issued Date</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.license_issued_date"
                                                        type="date"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1" v-if="supplier.type == 'Random Supplier' || supplier.type == 'Dealer'">
                                                <v-col cols="4" class="py-1">Renewal Date</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.license_renual_date"
                                                        type="date"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            
                                            <v-row class="mt-1" v-if="supplier.type == 'Random Supplier'">
                                                <v-col cols="4" class="py-1">Verified By</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.license_verified_by"
                                                        type="text"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1">
                                                <v-col cols="4" class="py-1">Office Phone</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.org_phone"
                                                        type="number"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <!-- <v-row class="mt-1">
                                                <v-col cols="4" class="py-1">Email</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="supplier.email"
                                                        type="email"
                                                        :rules="[() => validateEmail(supplier.email)]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row> -->

                                            <!-- <v-row class="mt-1">
                                                <v-col cols="4" class="py-1">Credit Limit</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.number="supplier.credit_limit"
                                                        :rules="[v => !!v]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row> -->

                                            <v-row no-gutters class="mb-1" style="display:none;">
                                                <v-col cols="4" class="py-1"></v-col>
                                                <v-col cols="8" class="px-2">
                                                    <v-checkbox
                                                        v-model="supplier.is_customer"
                                                        label="Is Customer"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1">
                                                <v-col cols="4" class="py-1"></v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-row no-gutters>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" block class="text_bg_fave">Save</v-btn>
                                                        </v-col>
                                                        <v-col cols="6" class="pl-1">
                                                            <v-btn dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="pb-0">
                        <v-data-table
                            class="custom-data-table shadow1 rounded py-4"
                            :headers="supplierHeaders"
                            :items="$store.getters['supplier/suppliers']"
                            :loading="$store.getters['supplier/loadingSuppliers']"
                            :search="searchsupplier" 
					        loading-text="Loading... Please wait"
                            @click:row="showDetails"
                        >
                            <template v-slot:top>
                                <v-toolbar dense color="white" :elevation="0" style="border-bottom: 1px solid #ddd !important;">
                                    <v-toolbar-title class="subtitle-2">Supplier List</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-form class="custom-form">
                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            placeholder="Search supplier"
                                            append-icon="mdi-magnify"
                                            style="width:300px;"
                                            v-model="searchsupplier"
                                        >
                                        </v-text-field>
                                    </v-form>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
            
            <v-col cols="12" md="3">
                <!-- image card -->
                <v-card dark color="white" :elevation="2" class="mb-1" v-if="supplier.type == 'Random Supplier' || supplier.type == 'Dealer'">
                    <v-card-text class="text-center pa-2">
                        <v-row class="pt-0">
                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="frontImagePreview" :src="frontImagePreview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input
                                        class="doc-image-input"
                                        hide-input
                                        truncate-length="15"
                                        accept=".png, .jpg"
                                        v-model="frontImage"
                                        @change="onChangeFrontImage()"
                                    ></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Front Image</h3>
                                </v-col>
                            </v-col>
                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="backImagePreview" :src="backImagePreview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input
                                        class="doc-image-input"
                                        hide-input
                                        truncate-length="15"
                                        accept=".png, .jpg"
                                        v-model="backImage"
                                        @change="onChangeBackImage()"
                                    ></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Back Img</h3>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row class="text-center pt-0">
                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="recieptImagePreview" :src="recieptImagePreview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input
                                        class="doc-image-input"
                                        hide-input
                                        truncate-length="15"
                                        accept=".png, .jpg"
                                        v-model="recieptImage"
                                        @change="onChangeRecieptImage()"
                                    ></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Reciept Img</h3>
                                </v-col>
                            </v-col>

                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="permissionImagePreview" :src="permissionImagePreview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input
                                        class="doc-image-input"
                                        hide-input
                                        truncate-length="15"
                                        accept=".png, .jpg"
                                        v-model="permissionImage"
                                        @change="onChangePermissionImage()"
                                    ></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Permission</h3>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card
                    tile
                    width="100%"
                    height="supplier.type == 'Impoter' ? '100%' : ''"
                    class="mx-auto rounded shadow1_important"
                >
                    <v-card dark tile flat color="#26ab68ad" class="carv_img_wrap">
                        <v-card-text class="text-center">
                            <!-- <v-avatar color="blue lighten-2" size="50" class="my-2">
                                <span class="white--text headline">C</span>
                            </v-avatar> -->

                            <!-- <h2 class="caption">{{ selectedSupplier.code }}</h2>
                            <h1 class="subtitle-2">{{ selectedSupplier.name }}</h1>

                            <div v-if="userType == 'super_admin' || userType == 'admin'">
                                <v-btn icon color="white" @click="editSupplier(selectedSupplier)">
                                    <v-icon>mdi-account-edit</v-icon>
                                </v-btn>
                                <v-btn icon color="white" @click="supplierId = selectedSupplier.id;$refs.confirmDialog.dialog = true">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div> -->
                            <img class="carv_img" :src="carv" alt="">
                            <img class="uavatar" :src="`https://ui-avatars.com/api/?name=${selectedSupplier.name}&background=26ab68ad&color=fff`" alt="alt">
                        </v-card-text>
                    </v-card>

                    <div class="actions_btns">
                        <h2 class="subtitle-1">{{ selectedSupplier.code }}</h2>
                        <h1 class="subtitle-1">{{ selectedSupplier.name }}</h1>
                        <div v-if="userType == 'super_admin' || userType == 'admin'">
                            <v-btn icon color="black" @click="editSupplier(selectedSupplier)">
                                <v-icon>mdi-account-edit</v-icon>
                            </v-btn>
                            <v-btn icon color="black" @click="supplierId = selectedSupplier.id;$refs.confirmDialog.dialog = true">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>

                    <v-list dense>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-cellphone</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedSupplier.phone }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-phone</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedSupplier.org_phone }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <!-- <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-email</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedSupplier.email }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        
                        <v-divider inset></v-divider>

                        <!-- <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-credit-card</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedSupplier.credit_limit }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-office-building</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedSupplier.org_name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-map-marker</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedSupplier.address }}</v-list-item-title>
                                <v-list-item-subtitle>{{ selectedSupplier.area ? selectedSupplier.area.name : '' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        
                        <v-list-item v-if="selectedSupplier.front_img" @click.prevent="()=> {
                                        downloadable = true;
                                        downloadableLink = selectedSupplier.front_img;
                                    }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Front Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="selectedSupplier.back_img" @click.prevent="()=> {
                                        downloadable = true;
                                        downloadableLink = selectedSupplier.back_img;
                                    }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Front Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="selectedSupplier.reciept_img" @click.prevent="()=> {
                                        downloadable = true;
                                        downloadableLink = selectedSupplier.reciept_img;
                                    }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Receipt Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="selectedSupplier.permission_img" @click.prevent="()=> {
                                downloadable = true;
                                downloadableLink = selectedSupplier.permission_img;
                            }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Permission Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>
                </v-card>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-dialog v-model="downloadable" width="auto">
                <v-card>
                    <v-card-text>
                        <img :src="downloadableLink" width="100%" />
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" block @click="downloadable=null">Close Dialog</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <delete-confirm ref="confirmDialog" @confirm="deletesupplier"></delete-confirm>
    </v-container>
</template>

<script>
import areaDialog from '../../components/area-dialog.component'
import utilities from '../../mixins/utility.mixin'
import confirmDialog from '../../components/confirm-dialog.component.vue'
import carv from '../../assets/shape_avatar.svg'

export default {
    name: 'Supplier',

    mixins: [utilities],

    components: {
        "area-dialog": areaDialog,
        "delete-confirm": confirmDialog
    },

    data: () => {
        return {
            downloadable: null,
            downloadableLink: null,
            supplierHeaders: [
                { text: 'SL.', value: 'sl' },
                { text: 'Type', value: 'type' },
                { text: 'Supplier Id', value: 'code' },
                { text: 'Name', value: 'name' },
                // { text: 'Company Name', value: 'org_name' },
                { text: 'Mobile', value: 'phone' },
                { text: 'address', value: 'address' },
            ],
            searchsupplier: '',
            carv: carv,
            supplier: {
                id: null,
                type: 'Random Supplier',
                code: '',
                name: '',
                father_name: '',
                area_id: null,
                address: '',
                phone: '',
                nid: '',
                license_no: '',
                license_issued_by: '',
                license_issued_date: null,
                license_renual_date: null,
                license_verified_by: '',
                org_name: '',
                org_phone: '',
                credit_limit: 900000000,
                is_customer: false
            },
            selectedSupplier: {
                id: null,
                code: 'C0001',
                name: 'Select a supplier',
                org_name: 'Company Name',
                credit_limit: 900000000,
                address: 'supplier\'s Address',
                mobile: '01XXXXXXXXX',
                // email: 'supplier\'s Email',
                org_phone: 'Office Phone',
                area: 'Area',

            },
            area: null,
            supplierId: null,
            waitingForSave: false,
            userType: '',

            frontImage: null,
            backImage: null,
            recieptImage: null,
            permissionImage: null,

            frontImagePreview: null,
            backImagePreview: null,
            recieptImagePreview: null,
            permissionImagePreview: null,
        }
    },

    watch: {
        area(area) {
            if(area == undefined) return;
            this.supplier.area_id = area.id;
        }
    },

    async created() {
        this.supplier.code = await this.$store.dispatch('supplier/generateSupplierCode');
        await this.$store.dispatch('supplier/getSuppliers');
        let userData = JSON.parse(localStorage.getItem('userData'))
		this.userType = userData.userType;
    },

    methods: {

        onChangeFrontImage() {
            if(event.target.files == undefined || event.target.files.length < 1) {
                this.frontImagePreview = '';
                this.frontImage = null;
                return;
            }
            this.frontImage = event.target.files[0];
            this.frontImagePreview = URL.createObjectURL(event.target.files[0]);
        },

        onChangeBackImage() {
            if(event.target.files == undefined || event.target.files.length < 1) {
                this.backImagePreview = '';
                this.backImage = null;
                return;
            }
            this.backImage = event.target.files[0];
            this.backImagePreview = URL.createObjectURL(event.target.files[0]);
        },

        onChangeRecieptImage() {
            if(event.target.files == undefined || event.target.files.length < 1) {
                this.recieptImagePreview = '';
                this.recieptImage = null;
                return;
            }
            this.recieptImage = event.target.files[0];
            this.recieptImagePreview = URL.createObjectURL(event.target.files[0]);
        },

        onChangePermissionImage() {
            if(event.target.files == undefined || event.target.files.length < 1) {
                this.permissionImagePreview = '';
                this.permissionImage = null;
                return;
            }
            this.permissionImage = event.target.files[0];
            this.permissionImagePreview = URL.createObjectURL(event.target.files[0]);
        },

        showDetails(supplier) {
            this.selectedSupplier = supplier;
            this.selectedSupplier.front_img = `${this.$store.state.hostName}/${supplier.front_img}`;
            this.selectedSupplier.back_img = `${this.$store.state.hostName}/${supplier.back_img}`;
            this.selectedSupplier.reciept_img = `${this.$store.state.hostName}/${supplier.reciept_img}`;
            this.selectedSupplier.permission_img = `${this.$store.state.hostName}/${supplier.permission_img}`;
        },

        editSupplier(supplier) {
             Object.keys(this.supplier).forEach(key => {
                this.supplier[key] = supplier[key];
            })
            
            this.area = supplier.area;

            this.frontImagePreview = `${this.$store.state.hostName}/${supplier.front_img}`;
            this.backImagePreview = `${this.$store.state.hostName}/${supplier.back_img}`;
            this.recieptImagePreview = `${this.$store.state.hostName}/${supplier.reciept_img}`;
            this.permissionImagePreview = `${this.$store.state.hostName}/${supplier.permission_img}`;
        },

        async saveSupplier() {
             if(!this.validatesupplier()) {
                return;
            }
     
            this.waitingForSave = true;

            let data = {
                supplier: this.supplier,
                frontImage: this.frontImage,
                backImage: this.backImage,
                recieptImage: this.recieptImage,
                permissionImage: this.permissionImage
            }

            let supplierccess = await this.$store.dispatch('supplier/saveSupplier', data);

            if(supplierccess) {
                this.resetForm();
            }

            this.waitingForSave = false
        },

        async deletesupplier() {
            await this.$store.dispatch('supplier/deleteSupplier', this.supplierId);
            this.$refs.confirmDialog.dialog = false;
        },

        validatesupplier() {
            let isValid = true;
            
            this.$refs.supplierForm.validate();

            this.$refs.supplierForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            if(!isValid) return;

            // if(this.supplier.area_id == null) {
            //     this.$store.dispatch('snackbar/error', 'Select a area');
            //     isValid = false; 
            //     return isValid;
            // }

            return isValid;
        },

        async resetForm() {
            Object.keys(this.supplier).map(k => this.supplier[k] = '');
            this.$refs.supplierForm.resetValidation();
            this.supplier.id = null;
            this.supplier.type = 'Random Supplier';
            this.supplier.is_customer = false;
            this.area = null;
            this.frontImagePreview = this.backImagePreview = this.recieptImagePreview = this.permissionImagePreview = null;
            this.frontImage = this.backImage = this.recieptImage = this.permissionImage = null;
            this.supplier.code = await this.$store.dispatch('supplier/generateSupplierCode');
        },
    }
}
</script>

<style lang="scss" scoped>

.carv_img_wrap {
    position: relative;
    padding-top: 84px;
}
.carv_img_wrap .carv_img {
    position: absolute;
    bottom: -26px;
    left: 0;
    right: 0;
    width: 144px;
    height: 62px;
    margin: 0 auto;
}
.uavatar {
    width: 64px;
    height: 64px;
    bottom: -32px;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}
.shadow_f {
    box-shadow: rgba(145, 158, 171, 0.16) 0px 4px 8px 0px !important;
}

// .info_list {
//     padding-top: 50px !important;
// }
.actions_btns {
    padding-top: 45px;
    text-align: center;
}
.v-input--selection-controls {
    margin-top: 5px;
}
.doc-subtitle {
    color: #000;
    font-size: 13px;
}

.position-relative {
    position: relative;
}
.doc-image-input {
    position: absolute;
    bottom: -15px;
    left: 40%;
}
.image-input {
    position: absolute;
    bottom: -15px;
    left: 40%;
}
::v-deep .v-icon.v-icon.v-icon--link {
    color: #000 !important;
}
::v-deep .v-btn--fab.v-size--x-small {
    margin-top: 0;
    height: 25px!important;
    width: 26px!important;
}
</style>